/*********************
* Fonts
*********************/
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url(https://fonts.gstatic.com/s/opensans/v27/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font: "Open Sans", sans-serif;

/*********************
* Colors
*********************/
$primary: #e6e6e6;
$secondary: #333333;

/*********************
* Reusable Styles
*********************/
/*** ID ***/

#container {
	font-family: $font;
	display: flex;
	flex-direction: column;
	max-width: 1920px;
	margin: 0 auto;
	min-height: 100%;

	main {
		flex-grow: 1;
	}
}

#skip_nav {
	position: fixed;
	top: -9999px;
	left: -9999px;
}

/*** Classes ***/

.btn {
	font-size: 20px;
	font-weight: 700;
	border-radius: 6px;
	border: 1px solid #000;
	background: #fff;
	display: inline-block;
	transition: background .3s;
	font-family: $font;
	text-decoration: none;
	color: #000;

	&:hover {
		background: $primary;
	}
}

.btn_arrow {
	font-size: 16px;
	font-weight: 700;
	color: #000;
	display: inline-block;
	border-radius: 6px;
	background: #f2f2f2;
	font-family: $font;
	text-decoration: none;
	transition: background .3s, color .3s;
	position: relative;
	padding: 12px 34px 12px 12px;

	&:hover {
		background: $secondary;
		color: #fff;

		&:after {
			border-top: 3px solid #fff;
			border-right: 3px solid #fff;
		}
	}

	&:after {
		position: absolute;
		content: '';
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
		right: 16px;
		border-top: 3px solid $secondary;
		border-right: 3px solid $secondary;
		border-bottom: transparent;
		border-left: transparent;
		height: 10px;
		width: 10px;
		transition: border .3s;
	}
}