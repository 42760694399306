#site_header {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 3em 10em;
}

#toggle_mobile_nav {
	display: block;
	position: absolute;
	height: 40px;
	width: 40px;
	padding: 0;
	margin: 0;
	background: darken($secondary, 10%);
	top: 45%;
	right: 20px;
	transform: translateY(-45%);
	border: none;

	&::before, &::after {
		display: block;
		content: "";
		width: 100%;
		height: 5px;
		margin: 10px 0;
		background: #fff;
		transition: transform .3s;
	}

	&.active {

		&::before {
			transform: translateY(8px) rotate(45deg);
		}

		&::after {
			transform: translateY(-8px) rotate(-45deg);
		}
	}

	@media screen and (min-width: 751px) {
		display: none;
	}
}

#site_header_nav {
	display: flex;

	li {

		&+li {
			margin-left: 4em;
		}
	}

	a {
		position: relative;
		text-decoration: none;
		color: #000;
		font-size: 18px;

		&::after {
			position: absolute;
			content: "";
			height: 2px;
			width: 100%;
			left: 0;
			bottom: 0;
			background: #000;
			transform: scaleX(0);
			transition: transform .3s;
		}

		&:hover {

			&::after {
				transform: scaleX(1);
			}
		}
	}
}

#mobile_nav_container {
	display: none;
}

/***>>Media Queries***/
@media screen and (max-width: 1500px) {

	#site_header {
		padding: 3em 5em;
	}

	#site_header_nav {

		li {

			&+li {
				margin-left: 2em;
			}
		}
	}
}

@media screen and (max-width: 1200px) {

	#site_header {
		padding: 3em 1em;
	}

	#site_header_nav {

		li {

			&+li {
				margin-left: 1em;
			}
		}

		a {
			font-size: 16px;
		}
	}
}

@media screen and (max-width: 950px) {

	#site_header {
		flex-direction: column;
	}

	#site_header_wrapper {
		margin-top: 1em;
	}

	#site_header_nav {

		li {

			&+li {
				margin-left: 2em;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#site_header {
		flex-direction: row;
		padding: 2em 1em;
	}

	#site_header_nav {
		display: none;
	}

	#mobile_nav_container {
		position: absolute;
		top: 100%;
		right: 0;
		width: 250px;
		height: 100vh;
		display: flex;
		justify-content: center;
		background: rgba(#000, .9);
		transform: scaleX(0);
		transform-origin: right;
		transition: transform .3s;
		z-index: 999;

		&.active {
			transform: scaleX(1);
		}
	}

	#mobile_nav {

		li {
			margin: 1em;
		}

		a {
			text-decoration: none;
			color: #fff;
			font-weight: 700;
		}
	}
}

@media screen and (max-width: 500px) {

	#site_header_logo {

		img {
			max-width: 275px;
		}
	}
}