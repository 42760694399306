#internal_header_wrapper {
	max-width: 1920px;
	margin: 0 auto;

	.internal_header {
		font-size: 48px;
		font-weight: 700;
		padding: 97px 35px;
		background-image: url('/dist/images/backgrounds/contact_header.jpg');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		text-align: center;
		color: #fff;
		text-shadow: 0px 5px 4px rgba(#000, .5);

		&.about {
			background-image: url('/dist/images/backgrounds/about_header.jpg');
			background-position: right;
		}

		&.maintenance {
			background-image: url('/dist/images/backgrounds/maintenance_header.jpg');
		}

		&.payments {
			background-image: url('/dist/images/backgrounds/paybill_header.jpg');
		}

		&.privacy {
			background-image: url('/dist/images/backgrounds/privacy_header.png');
		}

		&.accessibility {
			background-image: url('/dist/images/backgrounds/accessibility_header.png');
		}
	}
}

.internal_content_wrapper {
	background-image: url('/dist/images/content/cube_pattern.svg');
	position: relative;

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to bottom, #fff, transparent);
	}

	&.about, &.payments {
		padding: 0 0 150px;
	}
}

.internal_content {
	padding: 98px 35px 157px;
	max-width: 1020px;
	margin: 0 auto;
	position: relative;
	z-index: 1;

	&.error {
		text-align: center;

		p {
			line-height: 1.8;

			a {
				color: #4bc1ec;
				text-decoration: none;
				transition: color .3s;

				&:hover {
					color: darken(#4bc1ec, 20%);
				}
			}
		}
	}

	&.about {
		padding: 98px 35px 0;

		.about_dmi {
			line-height: 1.8;
			margin-bottom: 60px;
		}

		.about_core_elements {
			margin-bottom: 20px;
		}

		ul {
			list-style-type: none;
			margin-bottom: 105px;

			li {
				margin-left: 35px;
				line-height: 2.2;
				position: relative;

				&:before {
					position: absolute;
					content: '';
					background: #cbcbcb;
					width: 10px;
					height: 10px;
					transform: translateY(-50%) rotate(45deg);
					top: 1.1em;
					left: -20px;
				}
			}
		}
	}
}

#about_bio_wrapper {
	max-width: 1350px;
	margin: 0 auto;
	background: #e6e6e6;
	border-radius: 15px;
	padding: 35px;
	position: relative;
	z-index: 1;

	.bio_container {
		background: #fff;
		max-width: 1296px;
		margin: 0 auto 35px;
		border-radius: 15px;
		padding: 120px;
		display: flex;
		box-shadow: 0 10px 10px 4px rgba(#000, .20);
	}

	.bio_image {
		margin: 0 40px 0 0;
	}

	.bio_content {

		h3 {
			font-weight: 700;
			margin-bottom: 20px;
		}

		p {
			line-height: 1.8;
			margin-bottom: 60px;
		}
	}
}

#payment_fac_wrapper {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	max-width: 1350px;
	margin: 2em auto 0;
	padding: 35px 35px 0;
	background: $primary;
	border-radius: 5px;
	box-sizing: border-box;
	z-index: 1;

	h3 {
		font-weight: 800;
		margin-bottom: .5em;
	}

	.fac_payment_info {
		width: 49%;
		margin-bottom: 3em;
		padding: 2em;
		display: flex;
		background: #fff;
		border-radius: 5px;
		box-shadow: 0px 10px 10px 0px rgba(#000, .5);
		box-sizing: border-box;
	}

	.fac_placeholder_image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 200px;
		height: 150px;
		background: #ccc;
	}

	.fac_content_image {

		img {
			width: 200px;
			height: 150px;
		}
	}

	.fac_info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin-left: 1em;
	}

	.fac_payment_links {
		display: flex;
		margin-top: 2em;
	}

	.btn {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 120px;
		padding: 1em;
		background: #f2f2f2;
		border: none;
		font-size: 16px;

		&:hover {
			background: $primary;
		}

		&+.btn {
			margin-left: 1em;
		}
	}
}

@media screen and (max-width: 1420px) {

	#about_bio_wrapper {
		border-radius: 0;
	}
}

@media screen and (max-width: 1300px) {

	#payment_fac_wrapper {

		h3 {
			text-align: center;
		}

		div {
			text-align: center;
		}

		.fac_payment_info {
			flex-direction: column;
			align-items: center;
		}

		.fac_info {
			margin-left: 0;
		}

		.fac_placeholder_image, .fac_content_image {
			margin-bottom: 1em;
		}
	}
}

@media screen and (max-width: 750px) {

	#internal_header_wrapper {

		.internal_header {
			font-size: 34px;
			padding: 50px 35px;
		}
	}

	#about_bio_wrapper {

		.bio_container {
			flex-direction: column;
			align-items: center;
			padding: 60px;
		}

		.bio_image {
			margin: 0 0 35px 0;
		}
	}

	#payment_fac_wrapper {
		flex-direction: column;

		.fac_payment_info {
			width: 100%;
			padding: 2em 1em;
		}

		.fac_info {
			text-align: left;
			margin-left: 0;
		}


		.fac_payment_links {
			flex-direction: column;
			align-items: center;
			margin-top: 1em;

			.btn {

				&+.btn {
					margin-left: 0;
					margin-top: .5em;
				}
			}
		}
	}
}

@media screen and (max-width: 500px) {

	#internal_header_wrapper {

		.internal_header {
			font-size: 23px;
		}
	}

	.internal_content_wrapper {

		&.about {
			padding: 0 0 75px;
		}
	}

	.internal_content {

		&.about {
			padding: 60px 35px 0;

			ul {
				margin-bottom: 60px;
			}

			.about_dmi, .about_core_elements, li {
				font-size: 14px;
			}
		}
	}

	#about_bio_wrapper {

		.bio_container {
			padding: 35px;
		}

		.bio_content {

			p {
				font-size: 14px;
				margin-bottom: 35px;
			}
		}
	}
}