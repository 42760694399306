#home_hero {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 600px;
}

#home_hero_caro {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
	height: 100%;
	background: #ddd;

	.caro_inner {
		margin: 0;
	}

	.dots_container {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100px;
		bottom: 0;
		background: rgba($secondary, .7);
	}

	.caro_dot {
		width: 15px;
		height: 15px;
		border: none;
		background: #9e9e9e;
		box-shadow: none;
		margin: 0 10px;

		&.active {
			background: #fff;
		}
	}
}

#home_hero_text {
	position: relative;
	z-index: 2;
	text-align: center;
	text-shadow: 1px 1px 1px #000;

	h1 {
		color: #fff;
		font-size: 60px;
		font-weight: 600;
	}

	h2 {
		margin-top: .25em;
		color: #fff;
		font-size: 30px;
	}
}

#home_about_inner {
	max-width: 1200px;
	margin: 0 auto;
	padding: 5em;
	box-sizing: border-box;

	p {
		margin-bottom: 2em;
		text-align: center;
		line-height: 2;
	}

	a {
		color: #2aabe2;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

#home_cta {
	position: relative;
	padding: 50px 0;
	background-image: url('/dist/images/content/cube_pattern.svg');
	background-position: center;

	&::after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to top, #fff, transparent);
	}

	h3 {
		position: relative;
		margin-bottom: 50px;
		text-align: center;
		font-size: 36px;
		font-weight: 600;
		z-index: 1;
	}
}

#home_cta_cards {
	position: relative;
	max-width: 1300px;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-sizing: border-box;
	z-index: 1;

	.home_cta_card {
		position: relative;
		flex: 0 0 32%;
		text-decoration: none;
		transition: transform .3s;

		.cta_card_inner {
			height: 420px;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
		}

		h4 {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: flex-end;
			padding: 1.5em 1em;
			color: #fff;
			font-size: 24px;
			font-weight: 600;
			z-index: 1;
		}

		&:before {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			width: 100%;
			height: 23%;
			background: rgba(#000, .7);
			transition: height .3s;
		}

		&::after {
			position: absolute;
			content: "";
			bottom: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(#000, .7);
			transform: scaleY(0);
			transform-origin: 50% 78%;
			transition: transform .3s;
		}

		&:hover {
			transform: scale(1.1);

			&::before {
				height: 0%;
			}

			&::after {
				transform: scaleY(1);
			}

			h4 {
				background: none;
			}
		}
	}
}

#home_footer_border {
	padding: 1em;
	background: linear-gradient(90deg, #fff, $primary, #fff);
}
/***>>Media Queries***/
@media screen and (max-width: 1350px) {

	#home_cta {
		padding: 2em 1em;

		h3 {
			margin-bottom: 1em;
		}
	}

	#home_cta_cards {
		max-width: 100%;
		box-sizing: border-box;
	}
}

@media screen and (max-width: 1100px) {

	#home_hero_text {

		h1 {
			font-size: 48px;
		}

		h2 {
			font-size: 24px;
		}
	}

	#home_about_inner {
		padding: 2em 1em;
	}

	#home_cta {

		h3 {
			font-size: 30px;
		}
	}

	#home_cta_cards {

		.home_cta_card {

			h4 {
				font-size: 20px;
			}

			&::before {
				height: 20%;
			}

			&::after {
				transform-origin: 50% 81%;
			}
		}
	}
}

@media screen and (max-width: 950px) {

	#home_hero {
		height: 500px;
	}

	#home_hero_caro {

		.dots_container {
			height: 50px;
		}
	}

	#home_hero_text {

		h1 {
			font-size: 40px;
		}

		h2 {
			font-size: 20px;
		}
	}

	#home_about_inner {
		max-width: 100%;
	}

	#home_cta {

		h3 {
			font-size: 20px;
		}
	}

	#home_cta_cards {
		flex-direction: column;

		.home_cta_card {
			width: 320px;
			margin-bottom: 2em;

			h4 {
				font-size: 18px;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#home_hero {
		height: 350px;
	}

	#home_hero_text {
		padding: 0 .5em;

		h1 {
			font-size: 18px;
		}

		h2 {
			margin-top: .5em;
			font-size: 16px;
		}
	}
}