.internal_content_wrapper {

	&.contact {
		padding: 0 0 150px;
	}
}

#contact_form_wrapper {
	max-width: 1350px;
	margin: 2em auto 0;
	background: $primary;
	border-radius: 5px;
	padding: 35px 35px 150px;
	position: relative;
	z-index: 1;
}

#contact_form_inner {
	display: flex;
	padding: 5em 4em 2em;
	background: #fff;
	border-radius: 5px;
	box-shadow: 0px 10px 10px 0px rgba(#000, .5);
}

#contact_info_section {
	display: flex;
	flex-direction: column;
	width: 450px;
	margin-right: 5em;
	padding-top: 3em;
	border-right: 2px solid $primary;
	font-weight: 700;

	a {
		color: #2aabe2;
		text-decoration: none;
		font-weight: 500;

		&:hover {
			text-decoration: underline;
		}
	}

	.contact_info_address {
		line-height: 2;
	}

	.contact_info_phone {
		margin-top: .5em;

		div {
			margin-bottom: 1em;
		}
	}

	.contact_info_emails {
		margin-top: 3em;

		div {
			margin-bottom: 1em;
		}
	}
}

#contact_form {

	display: flex;
	flex-direction: column;
	flex: 1;
	font-weight: 700;

	.contact_input_wrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1em;

		&.textarea {
			align-items: flex-start;
		}
	}

	.btn {
		position: absolute;
		width: 250px;
		padding: .5em 0;
		bottom: 50px;
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
	}

	input, select, textarea {
		width: 80%;
		padding: .5em;
		color: #000;
		border: 1px solid #000;
		border-radius: 5px;
		font-size: 16px;
		font-family: $font;
		box-sizing: border-box;

		&.error {
			border-color: #c00;
		}
	}

	select {
		padding: .75em;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-appearance: none;
		-moz-appearance: none;
		background-image: url("/dist/images/icons/arrow_down_ic.svg");
		background-repeat: no-repeat;
		background-position: right 5% bottom 50%;
	}

	textarea {
		height: 150px;
	}

	.response_message {
		padding: 1em 0 2em;
		text-align: center;
		line-height: 1.5;

		.error {
			color: #c00;
		}

		.success {
			color: #090;
		}
	}
}

#hp_wrap {
	position: fixed;
	left: -9999px;
	top: -9999px;
}
/***>>>Media Queries***/

@media screen and (max-width: 1200px) {

	#contact_info_section {
		width: 380px;
		margin-right: 2em;
	}

	#contact_form {

		input, select, textarea {
			width: 70%;
		}
	}
}

@media screen and (max-width: 950px) {

	#contact_form_inner {
		padding: 2em;
	}

	#contact_info_section {
		width: 210px;
		margin-right: 2em;
		padding-top: 0;

		.contact_info_emails {

			div {
				margin-bottom: 2em;
			}

			a {
				display: block;
				margin-top: .5em;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#contact_form_inner {
		flex-direction: column;
	}

	#contact_info_section {
		width: auto;
		margin-right: 0;
		margin-bottom: 2em;
		border-right: none;
		border-bottom: 2px solid $primary;
		text-align: center;

		.contact_info_emails {
			margin-top: 1em;

			div {
				margin-bottom: 1em;
			}
		}
	}

	#contact_form {

		.contact_input_wrapper {
			flex-direction: column;
			align-items: flex-start;
		}

		.btn {
			width: 150px;
			bottom: 75px;
		}

		label {
			margin-bottom: .5em;
		}

		input, select, textarea {
			width: 100%;
		}
	}
}
