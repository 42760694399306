#legality {

	h3 {
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 20px;
		line-height: 1.4;
	}

	p {
		line-height: 1.8;
		margin-bottom: 20px;
	}

	a {
		word-break: break-all;

		color: #4bc1ec;
		text-decoration: none;
		transition: color .3s;

		&:hover {
			color: darken(#4bc1ec, 20%);
		}
	}

	ul {
		margin-bottom: 20px;

		li {
			margin-left: 35px;
			line-height: 2.2;
			position: relative;

			&:before {
				position: absolute;
				content: '';
				background: #cbcbcb;
				width: 10px;
				height: 10px;
				transform: translateY(-50%) rotate(45deg);
				top: 1.1em;
				left: -20px;
			}
		}
	}
}

@media screen and (min-width: 800px) and (max-width: 850px) {

	#legality {

		.internal_content_wrapper {
			background-image: none;

			&:after {
				background-image: none;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#legality {

		.internal_content_wrapper {
			background-image: none;

			&:after {
				background-image: none;
			}
		}

		.internal_content {
			padding: 35px 35px 60px;
		}
	}
}