#maintenance_form {
	display: flex;
	justify-content: center;
	padding: 33px;
}

#hp_wrap {
	position: absolute;
	top: -500px;
	left: -500px;
}

#form_shadow {
	width: 74.5%;
	padding: 35px;
	background-color: #e6e6e6;
	border-radius: 9px;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	flex-direction: column;
	z-index: 1;

	.response_message {
		margin-bottom: 30px;

		.success {
			color: #090;
		}

		.error {
			color: #af0000;
		}
	}

	.title {
		margin-bottom: 2%;
		font-weight: 700;
		font-size: 20px;
		width: 100%;
	}

	label {
		width: 15%;
		display: flex;
		align-items: center;
		font-weight: 900;
		font-family: "Open Sans", sans-serif;
	}

	input, select, textarea {
		border: 1px solid #000;
		border-radius: 5px;
		font-family: $font;
		font-size: 16px;
		padding-left: .5em;

		&.error {
			border: 1px solid #af0000;
		}

		&::placeholder {
			visibility: hidden;
		}
	}

	input {
		width: 85%;
		height: 35px;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		width: 55%;
		height: 39px;
		background: url(/dist/images/icons/arrow_down_icon.png) no-repeat;
		background-position-x: 98%;
		background-position-y: 50%;
		background-size: 46px;
		text-align: center;
	}

	.form_group {
		background-color: #ffffff;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 6% 10%;
		border-radius: 9px;
		width: 80%;
		box-shadow: rgba(0, 0, 0, .35) 0px 5px 15px;
		margin-bottom: 7.5%;

		&.checkList {
			padding: 3.5% 10%;
		}

		.form_field {
			display: flex;
			width: 100%;
			justify-content: start;

			& + .form_field {
				margin-top: 2%;
			}
		}
	}
}

#problem_list {

	.flex_wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
	}


	.problem_column {
		display: flex;
		box-sizing: border-box;
		flex-direction: column;
		width: 33%;

		.form_field {
			margin-left: 15.5%;


			& + .form_field {
				margin-top: 4%;
			}

			input {
				height: 25px;
				width: 25px;
				min-height: 25px;
				min-width: 25px;
			}

			label {
				margin-left: 20px;
				min-width: 12ch;
			}
		}
	}
}

#img_upload {
	display: flex;
	width: 100%;
	justify-content: start;
	align-items: center;
	margin-bottom: 5%;

	input[type="file"] {
		width: .1px;
		height: .1px;
		opacity: 0;
		overflow: hidden;
		position: absolute;
		z-index: -1;
	}

	.custom-file-upload {
		border: 1px solid #ccc;
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
	}

	input {
		width: 44%;
		height: 41px;
		background-color: #fff;
	}

	.choose_files_btn {
		background-color: #333333;
		color: #e6e6e6;
		padding: 0 3%;
		width: unset;
		margin-left: 20px;
		height: 45px;
		box-sizing: border-box;
		display: flex;
		justify-content: center;

		&:hover {
			background-color: #e6e6e6;
			color: #333333;
		}
	}
}

#comments_input {
	padding: 10px;
	width: 100%;
	border: none;
	height: 219px;
	border-radius: 9px;
	box-sizing: border-box;
	box-shadow: rgba(0, 0, 0, .35) 0px 5px 15px;
	margin-bottom: 4%;
	font-family: $font;
}

#maintenance_form_submit {
	padding: .5% 9%;
}

@media screen and (max-width: 1200px) {

	#form_shadow {
		width: 80%;

		label {
			min-width: 15ch;
		}
	}
}

@media screen and (max-width: 850px) {

	#maintenance_form {
		padding: 33px 0;
	}

	#form_shadow {
		width: 100%;

		.form_group {
			padding: 4% 6%;
		}

		select {
			background-size: 30px;
		}
	}

	#problem_list {

		.problem_column {

			.form_field {
				margin-left: 0;

				label {
					font-size: 14px;
				}
			}
		}
	}
}


@media screen and (max-width: 550px) {

	#form_shadow {

		select {
			width: 87%;
		}

		.form_group {

			&.checklist {
				padding: 1% 10%;
			}

			.form_field {
				flex-direction: column;
			}
		}
	}

	#problem_list {

		.flex_wrapper {
			flex-direction: column;

			.form_field {
				flex-direction: row;
			}
		}
	}

	#img_upload {
		flex-direction: column;
		align-items: start;

		input {
			width: 80%;
			margin-bottom: 10px;
		}

		.choose_files_btn {
			margin-left: 0;
		}
	}
}

